import {
  FormAppPreset,
  FORM_TEMPLATES,
  isEcomRequiredTemplate,
} from '../constants/templates';
import {
  getFormAppComponents,
  setComponentFormId,
  getComponentPresetId,
  getComponentFormId,
  handleBlankTemplate,
  handlePresetTemplate,
  createFormFromTemplate,
  getLinguistHeader,
} from './utils';
import type { EditorSDK } from '@wix/platform-editor-sdk';
import type { OwnerLogger, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  reportPublishedWidgets,
  reportAddedWidget,
  reportDeletedWidget,
} from './bi';
import { maybeInstallEcom, installEcomPages } from './ecom';
import { EXPERIMENTS } from '../constants/experiments';

interface OnWidgetAddedParams {
  sdk: EditorSDK;
  appToken: string;
  flowApi: FlowAPI;
}

export const onWidgetAdded = ({
  sdk,
  appToken,
  flowApi,
}: OnWidgetAddedParams) =>
  sdk.addEventListener(
    'widgetAdded',
    async ({ detail: { componentRef, originalComponentId } }) => {
      const { httpClient, bi, experiments } = flowApi;
      const components = await getFormAppComponents({ sdk, appToken });
      const component = components?.find((item) => item.id === componentRef.id);
      const presetId = getComponentPresetId(component);
      const msid = await sdk.info.getMetaSiteId(appToken);
      const linguistHeader = await getLinguistHeader(sdk, appToken, flowApi);

      reportAddedWidget({
        component,
        presetId,
        bi,
        msid,
      });

      const hasFormId = Boolean(getComponentFormId(component));
      if (hasFormId) {
        return;
      }

      const wasCopyPasted = Boolean(originalComponentId);

      if (presetId === FormAppPreset.Blank || presetId === undefined) {
        if (wasCopyPasted) {
          return;
        }
        await handleBlankTemplate({
          appToken,
          sdk,
          httpClient,
          componentRef,
          presetId,
        });
      } else if (presetId === FormAppPreset.Existing) {
        if (wasCopyPasted) {
          return;
        }
        await setTimeout(
          async () =>
            sdk.editor.openSettingsPanel(appToken, {
              componentRef,
            }),
          3000,
        );
      } else {
        if (presetId) {
          const createFormOnWidgetAdded = experiments.enabled(
            EXPERIMENTS.CREATE_FORM_ON_WIDGET_ADDED,
          );

          await Promise.all([
            handlePresetTemplate({
              appToken,
              sdk,
              httpClient,
              componentRef,
              presetId,
              createFormOnWidgetAdded,
              linguistHeader,
            }),
            isEcomRequiredTemplate(presetId) &&
              installEcomPages({ flowApi, sdk }),
          ]);
        }
      }
    },
  );

interface OnSiteSavedParams {
  sdk: EditorSDK;
  appToken: string;
  flowApi: FlowAPI;
}

export const onSiteSaved = ({ sdk, appToken, flowApi }: OnSiteSavedParams) =>
  sdk.addEventListener('siteWasSaved', async () => {
    const { experiments, httpClient } = flowApi;
    const createFormOnWidgetAdded = experiments.enabled(
      EXPERIMENTS.CREATE_FORM_ON_WIDGET_ADDED,
    );
    const linguistHeader = await getLinguistHeader(sdk, appToken, flowApi);

    if (createFormOnWidgetAdded) {
      return;
    }

    const components = await getFormAppComponents({ sdk, appToken });
    const componentsWithFormsToCreate = components?.filter((component) =>
      Object.values(FORM_TEMPLATES).includes(getComponentFormId(component)),
    );

    if (componentsWithFormsToCreate?.length) {
      componentsWithFormsToCreate.forEach(async (component) => {
        const form = await createFormFromTemplate({
          templateId: getComponentFormId(component),
          httpClient,
          linguistHeader,
        });

        await setComponentFormId({
          appToken,
          sdk,
          formId: form.id,
          compRef: { id: component.id, type: 'DESKTOP' },
        });
      });
    }
  });

interface OnComponentDeletedParams {
  sdk: EditorSDK;
  bi: OwnerLogger;
  appToken: string;
}

export const onComponentDeleted = ({
  sdk,
  bi,
  appToken,
}: OnComponentDeletedParams) =>
  sdk.addEventListener(
    'componentDeleted',
    async ({ detail: { componentRef } }) => {
      const msid = await sdk.info.getMetaSiteId(appToken);
      reportDeletedWidget({ componentRef, bi, msid });
    },
  );

interface OnSitePublishedParams {
  sdk: EditorSDK;
  appToken: string;
  bi: OwnerLogger;
}

export const onSitePublished = ({ sdk, appToken, bi }: OnSitePublishedParams) =>
  sdk.addEventListener('siteWasPublished', async () => {
    await reportPublishedWidgets({ sdk, appToken, bi });
  });

interface OnAppVisitedInDashboardParams {
  sdk: EditorSDK;
  flowApi: FlowAPI;
}

export const onAppVisitedInDashboard = ({
  sdk,
  flowApi,
}: OnAppVisitedInDashboardParams) => {
  sdk.addEventListener('appVisitedInDashboard', async () => {
    await maybeInstallEcom({ sdk, flowApi });
  });
};
